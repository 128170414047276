import React, { useState, useEffect, useRef } from 'react';
import { 
  Typography, Box, Card, CardContent, CardHeader,
  Tabs, Tab, TextField, Select, MenuItem, FormControlLabel,
  Switch, Button, Grid, InputLabel, FormControl, CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { LiveKitRoom, AudioConference, RoomAudioRenderer } from '@livekit/components-react';
import { Room, RoomEvent, ParticipantEvent } from 'livekit-client';
import { useLocation } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const businessTypes = [
  { value: "dentist", label: "Dentist" },
  { value: "clinic", label: "Medical Clinic" },
  { value: "yoga", label: "Yoga Studio" },
  { value: "gym", label: "Gym" },
  { value: "restaurant", label: "Restaurant" },
  { value: "carDealership", label: "Car Dealership" },
  { value: "realEstate", label: "Real Estate" },
  { value: "hospitality", label: "Hospitality" },
  { value: "other", label: "Other" }
];

const templateData = {
  dentist: {
    description: "We are a state-of-the-art dental practice committed to providing comprehensive oral care in a comfortable and friendly environment. Our team of experienced dentists specializes in preventive, restorative, and cosmetic dentistry for patients of all ages.",
    greeting: "Thank you for calling [Practice Name]. How may I help you with your dental needs today?",
    services: ["Cleaning", "Filling", "Root Canal", "Orthodontics", "Teeth Whitening"],
    faq: [
      "Q: Do you accept dental insurance?\nA: Yes, we accept most major dental insurance plans. Please have your insurance information ready when scheduling your appointment.",
      "Q: How often should I have a dental check-up?\nA: We recommend a check-up and cleaning every six months for most patients.",
      "Q: Do you offer emergency dental services?\nA: Yes, we provide emergency dental care. Please call us immediately if you're experiencing severe pain or have had an accident involving your teeth."
    ],
    schedule: {
      Monday: { open: "09:00", close: "17:00" },
      Tuesday: { open: "09:00", close: "17:00" },
      Wednesday: { open: "09:00", close: "17:00" },
      Thursday: { open: "09:00", close: "17:00" },
      Friday: { open: "09:00", close: "17:00" },
      Saturday: { open: "10:00", close: "14:00" },
      Sunday: { open: "Closed", close: "Closed" }
    }
  },
  carDealership: {
    description: "We are a premier car dealership offering a wide range of new and pre-owned vehicles. Our dedicated team of sales professionals is committed to helping you find the perfect car that fits your needs and budget. We also provide excellent after-sales service and maintenance.",
    greeting: "Thank you for calling [Dealership Name]. How may I assist you with your automotive needs today?",
    services: ["New Car Sales", "Pre-owned Vehicles", "Financing", "Trade-ins", "Service and Maintenance"],
    faq: [
      "Q: Do you offer test drives?\nA: Yes, we encourage test drives to help you make an informed decision. You can schedule a test drive online or by calling us.",
      "Q: What financing options do you offer?\nA: We offer a variety of financing options including loans and leases. Our finance team can help you find the best option for your situation.",
      "Q: Do you buy used cars?\nA: Yes, we do! We offer fair market value for trade-ins and can also purchase your vehicle outright."
    ],
    schedule: {
      Monday: { open: "09:00", close: "20:00" },
      Tuesday: { open: "09:00", close: "20:00" },
      Wednesday: { open: "09:00", close: "20:00" },
      Thursday: { open: "09:00", close: "20:00" },
      Friday: { open: "09:00", close: "20:00" },
      Saturday: { open: "10:00", close: "18:00" },
      Sunday: { open: "11:00", close: "16:00" }
    }
  },
  clinic: {
    description: "Our medical clinic offers comprehensive healthcare services for individuals and families. With a team of skilled physicians and modern facilities, we provide personalized care ranging from routine check-ups to specialized treatments.",
    greeting: "Welcome to [Clinic Name]. For medical emergencies, please hang up and dial 911. How may I assist you today?",
    services: ["General Consultation", "Vaccinations", "Lab Tests", "Specialist Referrals", "Annual Check-ups"],
    faq: [
      "Q: Do I need an appointment for a general check-up?\nA: Yes, we recommend scheduling an appointment for all visits to minimize wait times.",
      "Q: What should I bring to my first appointment?\nA: Please bring a valid ID, your insurance card, and a list of any current medications.",
      "Q: Do you offer telemedicine consultations?\nA: Yes, we provide telemedicine services for certain types of appointments. Please ask about this option when scheduling."
    ],
    schedule: {
      Monday: { open: "09:00", close: "17:00" },
      Tuesday: { open: "09:00", close: "17:00" },
      Wednesday: { open: "09:00", close: "17:00" },
      Thursday: { open: "09:00", close: "17:00" },
      Friday: { open: "09:00", close: "17:00" },
      Saturday: { open: "10:00", close: "14:00" },
      Sunday: { open: "Closed", close: "Closed" }
    }
  },
  yoga: {
    description: "Our yoga studio is a serene space dedicated to promoting physical and mental wellbeing through the practice of yoga. We offer a variety of classes suitable for beginners to advanced practitioners, led by experienced and certified instructors.",
    greeting: "Namaste! You've reached [Studio Name]. How can we help you on your yoga journey today?",
    services: ["Beginner Yoga", "Vinyasa Flow", "Hot Yoga", "Meditation", "Private Sessions"],
    faq: [
      "Q: What should I bring to class?\nA: Please bring a yoga mat, water bottle, and towel. We have mats available for rent if needed.",
      "Q: Do you offer classes for beginners?\nA: Yes, we have beginner-friendly classes and workshops perfect for those new to yoga.",
      "Q: How early should I arrive for class?\nA: We recommend arriving 10-15 minutes before class to get settled and set up your space."
    ],
    schedule: {
      Monday: { open: "07:00", close: "20:00" },
      Tuesday: { open: "07:00", close: "20:00" },
      Wednesday: { open: "07:00", close: "20:00" },
      Thursday: { open: "07:00", close: "20:00" },
      Friday: { open: "07:00", close: "20:00" },
      Saturday: { open: "09:00", close: "17:00" },
      Sunday: { open: "09:00", close: "17:00" }
    }
  },
  gym: {
    description: "Our fitness center is equipped with state-of-the-art exercise equipment and offers a wide range of group classes. Whether you're looking to build strength, improve cardiovascular health, or enhance flexibility, our certified trainers are here to help you achieve your fitness goals.",
    greeting: "Welcome to [Gym Name], where fitness meets community. How can we help you reach your fitness goals today?",
    services: ["Personal Training", "Group Classes", "Weight Room Access", "Cardio Equipment", "Nutrition Counseling"],
    faq: [
      "Q: Do you offer trial memberships?\nA: Yes, we offer a 7-day trial for new members. You can sign up for this on our website or in person.",
      "Q: What are your busiest hours?\nA: Our peak hours are typically weekdays from 6-8 AM and 5-7 PM. For a quieter workout, we recommend mid-morning or early afternoon visits.",
      "Q: Do you provide towels?\nA: Yes, we provide towels for members. You can pick them up at the front desk."
    ],
    schedule: {
      Monday: { open: "06:00", close: "22:00" },
      Tuesday: { open: "06:00", close: "22:00" },
      Wednesday: { open: "06:00", close: "22:00" },
      Thursday: { open: "06:00", close: "22:00" },
      Friday: { open: "06:00", close: "22:00" },
      Saturday: { open: "08:00", close: "20:00" },
      Sunday: { open: "08:00", close: "20:00" }
    }
  },
  restaurant: {
    description: "Our restaurant offers a unique dining experience with a menu that blends traditional favorites and innovative culinary creations. We pride ourselves on using fresh, locally-sourced ingredients to create memorable meals in a warm and inviting atmosphere.",
    greeting: "Thank you for calling [Restaurant Name]. How may I assist you with your dining needs today?",
    services: ["Reservations", "Takeout Orders", "Catering", "Special Event Booking", "Gift Card Purchase"],
    faq: [
      "Q: Do you take reservations?\nA: Yes, we accept reservations. You can make one now or visit our website to book online.",
      "Q: What are your hours of operation?\nA: Our hours are [insert hours]. We're closed on [insert closed days].",
      "Q: Do you offer vegetarian/vegan options?\nA: Yes, we have several vegetarian and vegan dishes on our menu. Our staff can guide you through the options."
    ],
    schedule: {
      Monday: { open: "11:00", close: "22:00" },
      Tuesday: { open: "11:00", close: "22:00" },
      Wednesday: { open: "11:00", close: "22:00" },
      Thursday: { open: "11:00", close: "22:00" },
      Friday: { open: "11:00", close: "23:00" },
      Saturday: { open: "11:00", close: "23:00" },
      Sunday: { open: "12:00", close: "21:00" }
    }
  },
  realEstate: {
    description: "We are a professional real estate agency dedicated to helping clients buy, sell, and rent properties. Our experienced team of real estate agents provides personalized service and expert guidance throughout every step of your real estate journey.",
    greeting: "Thank you for calling [Agency Name]. How may we assist you with your real estate needs today?",
    services: ["Property Sales", "Property Rentals", "Property Management", "Property Valuation", "Investment Consulting"],
    faq: [
      "Q: How do I schedule a property viewing?\nA: You can schedule a viewing by phone or through our website. We offer both in-person and virtual tours for your convenience.",
      "Q: What documents do I need to rent a property?\nA: Typically, you'll need proof of income, employment verification, photo ID, and references. We can provide a detailed checklist based on the specific property.",
      "Q: Do you help with mortgage pre-approval?\nA: Yes, we work with several trusted mortgage lenders and can refer you to professionals who can assist with the pre-approval process."
    ],
    schedule: {
      Monday: { open: "09:00", close: "18:00" },
      Tuesday: { open: "09:00", close: "18:00" },
      Wednesday: { open: "09:00", close: "18:00" },
      Thursday: { open: "09:00", close: "18:00" },
      Friday: { open: "09:00", close: "18:00" },
      Saturday: { open: "10:00", close: "16:00" },
      Sunday: { open: "Closed", close: "Closed" }
    }
  },
  hospitality: {
    description: "We are a premier hospitality establishment dedicated to providing exceptional guest experiences. Our professional staff ensures personalized service, comfortable accommodations, and memorable stays for all our guests.",
    greeting: "Thank you for calling [Hotel Name]. How may we assist you with your stay today?",
    services: ["Room Reservations", "Concierge Services", "Room Service", "Event Planning", "Airport Shuttle"],
    faq: [
      "Q: What is your check-in/check-out time?\nA: Check-in time is 3:00 PM and check-out time is 11:00 AM. Early check-in and late check-out may be available upon request.",
      "Q: Do you offer airport transportation?\nA: Yes, we provide shuttle service to and from the airport. Please contact us at least 24 hours in advance to arrange transportation.",
      "Q: What amenities are included with the room?\nA: Our rooms include complimentary Wi-Fi, flat-screen TV, mini-fridge, coffee maker, and daily housekeeping service. Additional amenities vary by room type."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  },
  other: {
    description: "We are a unique business dedicated to providing excellent service to our customers. Our team of professionals is committed to meeting your specific needs with the highest standards of quality and care.",
    greeting: "Thank you for contacting [Business Name]. How may I help you today?",
    services: ["Service 1", "Service 2", "Service 3", "Service 4", "Service 5"],
    faq: [
      "Q: What are your business hours?\nA: Our regular business hours are [insert hours]. Please check our website for any holiday schedule changes.",
      "Q: Do you offer consultations?\nA: Yes, we offer initial consultations to discuss your needs and how we can best serve you. Please contact us to schedule an appointment.",
      "Q: What forms of payment do you accept?\nA: We accept cash, all major credit cards, and digital payment methods. Please ask about our current payment options."
    ],
    schedule: {
      Monday: { open: "09:00", close: "17:00" },
      Tuesday: { open: "09:00", close: "17:00" },
      Wednesday: { open: "09:00", close: "17:00" },
      Thursday: { open: "09:00", close: "17:00" },
      Friday: { open: "09:00", close: "17:00" },
      Saturday: { open: "Closed", close: "Closed" },
      Sunday: { open: "Closed", close: "Closed" }
    }
  }
};
const AutoResizeTextarea = styled(TextField)({
  '& .MuiInputBase-root': {
    height: 'auto',
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    resize: 'none',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function BusinessDashboardPreview() {
  const [businessType, setBusinessType] = useState("");
  const [customData, setCustomData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [businessName, setBusinessName] = useState("");
  const [aiVoice, setAiVoice] = useState("");
  const [availableVoices, setAvailableVoices] = useState([]);
  const [afterHoursMessaging, setAfterHoursMessaging] = useState(false);
  const [appointmentDuration, setAppointmentDuration] = useState("");
  const [bufferTime, setBufferTime] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [liveKitToken, setLiveKitToken] = useState("");  // State for LiveKit token
  const [liveKitServerUrl, setLiveKitServerUrl] = useState("");  // State for LiveKit server URL
  const [connectLiveKit, setConnectLiveKit] = useState(false);  // State to handle user gesture for audio context
  const [mediaStream, setMediaStream] = useState(null); // State to manage media stream
  const [isCallActive, setIsCallActive] = useState(false); // New state to track if a call is active
  const [loading, setLoading] = useState(true); // New state for loading
  const [dataLoaded, setDataLoaded] = useState(false); // New state to track if data has been loaded
  const [browserSupported, setBrowserSupported] = useState(true);
  const roomRef = useRef(null);  // Reference to the Room instance
  const audioElementsRef = useRef({});  // Reference to store audio elements
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('access_token');
    if (token) {
      localStorage.setItem('accessToken', token);
      // Remove access_token from URL
      params.delete('access_token');
      fetchAgentConfiguration();
      fetchVoices();
      // navigate({
      //   pathname: location.pathname,
      //   search: params.toString(),
      // }, { replace: true });
    } else {
      const accessToken = localStorage.getItem('accessToken');
  
      if (!accessToken) {
        window.location.href = "https://aivira.co/";
      }
      fetchAgentConfiguration();
      fetchVoices();
    }
    // Check browser support
    const isSupported = 'RTCPeerConnection' in window;
    setBrowserSupported(isSupported);
  }, []); // Removed navigate from dependency array

  const requestAudioPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (error) {
      console.error('Error requesting audio permission:', error);
      return false;
    }
  };
  const fetchAgentConfiguration = async () => {
      setLoading(true);
      try {
        const accessToken = localStorage.getItem('accessToken');
  
        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }
  
        const response = await fetch('https://api.aivira.co/ai-receptionist', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
  
        const data = await response.json();
  
        if (response.ok) {
          // Populate state with existing configuration
          setBusinessType(data.businessType);
          setBusinessName(data.businessName);
          
          // Parse JSON strings to JavaScript objects/arrays
          const faqArray = JSON.parse(data.faq); // Convert JSON string to array
          const servicesArray = JSON.parse(data.services); // Convert JSON string to array
          const scheduleObject = JSON.parse(data.schedule); // Convert JSON string to object

          setCustomData({
            description: data.description,
            greeting: data.greeting,
            faq: faqArray,
            services: servicesArray,
            schedule: scheduleObject,
          });

          setAiVoice(data.aiVoice);
          setDataLoaded(true);
        } else if (response.status === 404) {
          console.log('No agent found for this user.');
          // Handle case where there's no existing agent
          setDataLoaded(false);
        } else {
          console.error('Failed to fetch agent configuration:', data.error);
          setDataLoaded(false);
        }
      } catch (error) {
        console.error('Error fetching agent configuration:', error);
        setDataLoaded(false);
      } finally {
        setLoading(false);
      }
    };
  
  useEffect(() => {
    fetchAgentConfiguration();
  }, []);

  // Fetch available voices
    const fetchVoices = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch('https://api.aivira.co/list-voices', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
  
        const voices = await response.json();
        setAvailableVoices(voices);
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };
    
  useEffect(() => {
  
    fetchVoices();
  }, []);

  // Fetch live call data on load
  useEffect(() => {
    const fetchLiveCallData = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }

        const response = await fetch('https://api.aivira.co/create-web-call', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            businessType,
            businessName,
          }),
        });

        const result = await response.json();

        if (response.ok) {
          const wsUrl = `wss://retell-ai-4ihahnq7.livekit.cloud/rtc?access_token=${result.access_token}&auto_subscribe=1&sdk=js&version=2.5.1&protocol=15`;
          setLiveKitToken(result.access_token);  // Set token state
          setLiveKitServerUrl(wsUrl);  // Set server URL state
          const room = new Room();
          await room.connect(wsUrl, result.access_token);
          console.log('connected to room', room.name);
          roomRef.current = room;  // Store room in ref
    
          // Publish local camera and mic tracks
          await room.localParticipant.setMicrophoneEnabled(true);
    
        } else {
          throw new Error(result.error || 'Failed to fetch live call data');
        }
      } catch (error) {
        console.error('Error fetching live call data:', error);
      }
    };

    if (connectLiveKit) {
      fetchLiveCallData();
    }
  }, [connectLiveKit]);  // Fetch again if connectLiveKit or mediaStream changes

  const handleBusinessTypeChange = (event) => {
    const value = event.target.value;
    setBusinessType(value);
    setCustomData(templateData[value] || {});
    setHasUnsavedChanges(true);
    // Scroll to the General settings section
    const generalSettingsSection = document.getElementById('general-settings-section');
    if (generalSettingsSection) {
      generalSettingsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleScheduleChange = (day, type, value) => {
    setCustomData(prevData => ({
      ...prevData,
      schedule: {
        ...prevData.schedule,
        [day]: {
          ...prevData.schedule[day],
          [type]: value
        }
      }
    }));
    setHasUnsavedChanges(true);
  };

  const handleInputChange = () => {
    setHasUnsavedChanges(true);
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach(textarea => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    });
  }, [customData]);

const handleSave = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error('Access token is missing. Please log in again.');
    }

    if (!aiVoice) {
      throw new Error('AI Voice is required. Please select an AI Voice before saving.');
    }

    const response = await fetch('https://api.aivira.co/ai-receptionist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        businessType,
        businessName,
        description: customData.description,
        aiVoice,
        afterHoursMessaging,
        greeting: customData.greeting,
        faq: customData.faq,
        services: customData.services,
        schedule: customData.schedule,
        appointmentDuration,
        bufferTime,
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || 'Failed to save AI receptionist configuration');
    }

    console.log('AI receptionist configuration saved:', result);
    setHasUnsavedChanges(false);
    // alert('Configuration saved successfully!');
  } catch (error) {
    console.error('Error saving AI receptionist configuration:', error);
    alert(error.message);
  }
};

const handleConnectLiveKit = async () => {
  if (!isCallActive) {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      setLoading(true);
      const response = await fetch('https://api.aivira.co/create-web-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          businessType,
          businessName,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || 'Failed to create web call');
      }

      const wsUrl = `wss://retell-ai-4ihahnq7.livekit.cloud`;
      setLiveKitToken(result.access_token);
      setLiveKitServerUrl(wsUrl);
      setIsCallActive(true);

    } catch (error) {
      console.error('Error in handleConnectLiveKit:', error);
      alert(error.message || 'Failed to start audio test. Please try again.');
    } finally {
      setLoading(false);
    }
  } else {
    setIsCallActive(false);
    setLiveKitToken('');
    setLiveKitServerUrl('');
  }
};


if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dataLoaded) {
    return (
      <Box sx={{ p: 4, maxWidth: '4xl', mx: 'auto' }}>
        <Card>
          <CardHeader title="Welcome to Aivira AI!" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              It looks like you haven't set up your AI receptionist yet. Let's get started!
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => setDataLoaded(true)}
              sx={{ mt: 2 }}
            >
              Set Up Your AI Receptionist
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '4xl', mx: 'auto' }}>
      <Card sx={{ mb: 4 }}>
        <CardHeader 
          title="Select Your Business Type"
          subheader="We'll customize the AI receptionist based on your business needs"
          action={
            <div>
              <Button 
                variant="contained" 
                color={isCallActive ? "error" : "primary"} 
                onClick={handleConnectLiveKit}
                sx={{ backgroundColor: isCallActive ? "#d32f2f" : undefined }}
              >
                {isCallActive ? "End the call" : "Test Audio"}
              </Button>
            </div>
          }
        />
        <CardContent>
          <FormControl fullWidth>
            <InputLabel id="business-type-label">Choose your business type</InputLabel>
            <Select
              labelId="business-type-label"
              value={businessType}
              onChange={handleBusinessTypeChange}
              label="Choose your business type"
            >
              {businessTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      {/* LiveKit Room */}
      {isCallActive && liveKitToken && liveKitServerUrl && (
        <div className="hidden">
          <LiveKitRoom
            serverUrl={liveKitServerUrl}
            token={liveKitToken}
            connect={true}
            audio={true}
            video={false}
            options={{
              adaptiveStream: true,
              dynacast: true,
              stopMicTrackOnMute: false,
              publishDefaults: {
                simulcast: true,
                audioPreset: {
                  maxBitrate: 96000,
                  maxQuality: true
                }
              }
            }}
          >
            <div className="hidden">
              <AudioConference />
              <RoomAudioRenderer />
            </div>
          </LiveKitRoom>
        </div>
      )}

      {businessType && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="dashboard tabs">
              <Tab label="General" />
              <Tab label="Scripts" />
              <Tab label="Services" />
              <Tab label="Schedule" />
            </Tabs>
          </Box>
          <Box sx={{ height: '100%', overflow: 'auto' }}>
          
          <TabPanel value={tabValue} index={0}>
            <Card id="general-settings-section">
              <CardHeader 
                title="General Settings" 
                subheader="Customize your AI receptionist's voice and behavior" 
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                  <TextField 
                    fullWidth 
                    label="Business Name" 
                    placeholder="Enter your business name"
                    value={businessName}
                    onChange={(e) => {
                      setBusinessName(e.target.value);
                      handleInputChange();
                    }}
                  />
                  <AutoResizeTextarea
                    fullWidth
                    multiline
                    label="Business Description"
                    placeholder="Provide a brief description of your business"
                    value={customData.description || ""}
                    onChange={(e) => {
                      setCustomData({...customData, description: e.target.value});
                      handleInputChange();
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="ai-voice-label">AI Voice</InputLabel>
                    <Select
                      labelId="ai-voice-label"
                      label="AI Voice"
                      value={aiVoice}
                      onChange={(e) => {
                        setAiVoice(e.target.value);
                        handleInputChange();
                      }}
                    >
                      {availableVoices && availableVoices.map((voice) => (
                        <MenuItem key={voice.voice_id} value={voice.voice_id}>
                          {voice.voice_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={afterHoursMessaging}
                        onChange={(e) => {
                          setAfterHoursMessaging(e.target.checked);
                          handleInputChange();
                        }}
                      />
                    }
                    label="Enable after-hours messaging"
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          
          <TabPanel value={tabValue} index={1}>
            <Card>
              <CardHeader 
                title="Scripts & FAQs" 
                subheader="Customize your greeting and frequently asked questions"
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                  <AutoResizeTextarea
                    fullWidth
                    multiline
                    label="Greeting Message"
                    placeholder="Enter your greeting message"
                    value={customData.greeting || ""}
                    onChange={(e) => {
                      setCustomData({...customData, greeting: e.target.value});
                      handleInputChange();
                    }}
                  />
                  <AutoResizeTextarea
                    fullWidth
                    multiline
                    label="Frequently Asked Questions"
                    placeholder="Enter FAQ in Q&A format"
                    value={customData.faq ? customData.faq.join("\n\n") : ""}
                    onChange={(e) => {
                      setCustomData({...customData, faq: e.target.value.split("\n\n")});
                      handleInputChange();
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          
          <TabPanel value={tabValue} index={2}>
            <Card>
              <CardHeader 
                title="Services" 
                subheader="Manage the services your business offers"
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Services Offered</Typography>
                {customData.services && customData.services.map((service, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <TextField 
                      value={service}
                      onChange={(e) => {
                        const newServices = [...customData.services];
                        newServices[index] = e.target.value;
                        setCustomData({...customData, services: newServices});
                        handleInputChange();
                      }}
                      fullWidth
                      sx={{ mr: 2 }}
                    />
                    <Button 
                      variant="outlined" 
                      size="small"
                      onClick={() => {
                        const newServices = customData.services.filter((_, i) => i !== index);
                        setCustomData({...customData, services: newServices});
                        handleInputChange();
                      }}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
                <Button 
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={() => {
                    const newServices = [...(customData.services || []), "New Service"];
                    setCustomData({...customData, services: newServices});
                    handleInputChange();
                  }}
                >
                  Add Service
                </Button>
              </CardContent>
            </Card>
          </TabPanel>
          
          <TabPanel value={tabValue} index={3}>
            <Card>
              <CardHeader 
                title="Scheduling" 
                subheader="Set up your business hours and appointment settings"
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Business Hours</Typography>
                <Grid container spacing={2}>
                  {Object.entries(customData.schedule || {}).map(([day, hours]) => (
                    <Grid item xs={12} key={day}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ width: 100 }}>{day}</Typography>
                        <TextField 
                          type="time" 
                          sx={{ width: 140, mr: 2 }} 
                          value={hours.open}
                          onChange={(e) => handleScheduleChange(day, 'open', e.target.value)}
                          disabled={hours.open === "Closed"}
                        />
                        <Typography sx={{ mx: 2 }}>to</Typography>
                        <TextField 
                          type="time" 
                          sx={{ width: 140 }} 
                          value={hours.close}
                          onChange={(e) => handleScheduleChange(day, 'close', e.target.value)}
                          disabled={hours.close === "Closed"}
                        />
                        <FormControlLabel
                          control={
                            <Switch 
                              checked={hours.open !== "Closed"}
                              onChange={(e) => {
                                const newValue = e.target.checked ? "09:00" : "Closed";
                                handleScheduleChange(day, 'open', newValue);
                                handleScheduleChange(day, 'close', e.target.checked ? "17:00" : "Closed");
                              }}
                            />
                          }
                          label="Open"
                          sx={{ ml: 2 }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="appointment-duration-label">Appointment Duration</InputLabel>
                  <Select 
                    labelId="appointment-duration-label" 
                    label="Appointment Duration"
                    value={appointmentDuration}
                    onChange={(e) => {
                      setAppointmentDuration(e.target.value);
                      handleInputChange();
                    }}
                  >
                    <MenuItem value="15">15 minutes</MenuItem>
                    <MenuItem value="30">30 minutes</MenuItem>
                    <MenuItem value="60">1 hour</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={bufferTime}
                      onChange={(e) => {
                        setBufferTime(e.target.checked);
                        handleInputChange();
                      }}
                    />
                  }
                  label="Add buffer time between appointments"
                  sx={{ mt: 2 }}
                />
              </CardContent>
            </Card>
          </TabPanel>
          </Box>
        </Box>
      )}
    </Box>
  );
}
