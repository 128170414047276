import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import LanguageSelector from './LanguageSelector';
import { Menu, Check, ChevronDown, ChevronUp, Phone, Mic, X } from 'lucide-react';
import { useTranslation } from '../hooks/useTranslation';

const CookiePopup = ({ onAccept, onDecline }) => {
  const { t } = useTranslation();
  
  return (
    <div className="fixed bottom-4 left-4 right-4 bg-white p-6 rounded-lg shadow-lg z-50 max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4 flex items-center">
        {t('cookies.title')}
        <span className="ml-2">🍪</span>
      </h2>
      <p className="mb-4">
        {t('cookies.description')}
      </p>
      <p className="mb-4">
        {t('cookies.consent')}
      </p>
      <div className="flex justify-end space-x-4">
        <Button
          variant="outline"
          className="px-6 py-2 text-gray-700 hover:bg-gray-100"
          onClick={onDecline}
        >
          {t('cookies.decline')}
        </Button>
        <Button
          className="px-6 py-2 bg-purple-600 text-white hover:bg-purple-700"
          onClick={onAccept}
        >
          {t('cookies.accept')}
        </Button>
      </div>
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        onClick={onDecline}
      >
        <X size={24} />
      </button>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold">{question}</h3>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

const BMWContactOptions = () => {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  
  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  return (
    <div className="w-full max-w-4xl mx-auto p-8 rounded-3xl bg-gradient-to-r from-blue-500 to-purple-600">
      <h1 className="text-3xl font-bold text-white text-center mb-6">
        {t('bmwContact.title')}
      </h1>
      
      <p className="text-xl text-white/90 text-center mb-12">
        {t('bmwContact.subtitle')}
      </p>
      <div className="flex flex-col items-center justify-center mb-10">
        <a 
          href="tel:+16827305052"
          className="flex items-center gap-3 px-8 py-4 bg-white rounded-full text-blue-600 font-semibold text-xl hover:bg-blue-50 transition-colors"
        >
          <Phone className="w-6 h-6" />
          +1 (682) 730-5052
        </a>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <div className="p-6 rounded-xl bg-white/10 backdrop-blur-sm text-center">
          <h3 className="text-xl font-semibold text-white mb-2">{t('bmwContact.services.testDrive.title')}</h3>
          <p className="text-white/80">{t('bmwContact.services.testDrive.description')}</p>
        </div>
        <div className="p-6 rounded-xl bg-white/10 backdrop-blur-sm text-center">
          <h3 className="text-xl font-semibold text-white mb-2">{t('bmwContact.services.models.title')}</h3>
          <p className="text-white/80">{t('bmwContact.services.models.description')}</p>
        </div>
        <div className="p-6 rounded-xl bg-white/10 backdrop-blur-sm text-center">
          <h3 className="text-xl font-semibold text-white mb-2">{t('bmwContact.services.service.title')}</h3>
          <p className="text-white/80">{t('bmwContact.services.service.description')}</p>
        </div>
      </div>

      <style jsx>{`
        @keyframes float {
          0% { transform: translateY(0px); }
          100% { transform: translateY(-8px); }
        }
      `}</style>
    </div>
  );
};

const HomePage = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [titleWord, setTitleWord] = useState('Car Dealers');
  const [demoType, setDemoType] = useState('phone');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const howItWorksRef = useRef(null);
  const featuresRef = useRef(null);
  const integrationsRef = useRef(null);
  const faqRef = useRef(null);
  const videoRef = useRef(null);
  const pricingRef = useRef(null);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setShowCookiePopup(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookiePopup(false);
  };

  const handleDeclineCookies = () => {
    setShowCookiePopup(false);
  };

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
    setIsMenuOpen(false);
  };

  const handleGetTheCall = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.aivira.co/create-phone-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_number: '+17194262639',
          to_number: phoneNumber,
          name,
          email,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create phone call');
      }

      // Handle successful response
      alert('Call request submitted successfully!');
      // Clear form fields
      setPhoneNumber('');
      setName('');
      setEmail('');
    } catch (err) {
      setError('An error occurred. Please try again.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const navItems = [
    { name: 'How it works', ref: howItWorksRef },
    { name: 'Features', ref: featuresRef },
    { name: 'Pricing', ref: pricingRef },
    { name: 'Community', href: 'https://discord.gg/3PQMZs8Rvz', target: '_blank' },
    { name: 'Blog', href: '/blog' },
  ];

  const videoUrl = "https://www.youtube.com/embed/6fZ3PXyl_fU?autoplay=1&enablejsapi=1";

  const scrollToVideoAndPlay = () => {
    scrollTo(videoRef);
    const iframe = videoRef.current;
    if (iframe) {
      iframe.src = videoUrl;
      // For mobile devices, we need to manually start the video
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      }
    }
  };

  const faqItems = [
    {
      question: "How does Aivira's implementation process work?",
      answer: "We start with a 30-day pilot where we work closely with your business to customize your AI receptionist (what information to collect for bookings, how to handle inquiries...etc). During this period, we deploy our software incrementally by first only handling a few calls a day, and gradually taking more until the end of the pilot. We are building a new, cutting edge technology and our goal is to get you comfortable with it before we manage 100% of your calls."
    },
    {
      question: "Is Aivira compatible with existing business software?",
      answer: "Yes, Aivira is designed to integrate seamlessly with a wide range of business management software. We support popular systems across various industries. If you use a specific system, please contact us to discuss compatibility."
    },
    {
      question: "How secure is Aivira's technology?",
      answer: "Aivira takes security and compliance very seriously. Our technology adheres to the highest industry standards, ensuring that all customer data is protected. We use advanced encryption methods, implement strict access controls, and regularly undergo security audits to maintain the integrity and confidentiality of your data."
    }
  ];

  const useCases = [
  { name: 'Healthcare', path: '/healthcare', icon: '🏥', description: 'Streamline patient care and appointments' },
  { name: 'Education', path: '/education', icon: '🎓', description: 'Enhance student and parent communication' },
  { name: 'Legal', path: '/legal', icon: '⚖️', description: 'Manage client inquiries and consultations' },
  { name: 'Real Estate', path: '/real-estate', icon: '🏠', description: 'Handle property inquiries and showings' },
  { name: 'Car Dealers', path: '/car-dealers', icon: '🚗', description: 'Manage service appointments and sales inquiries' },
  { name: 'Hospitality', path: '/hospitality', icon: '🏨', description: 'Streamline reservations and guest services' },
  { name: 'Fitness', path: '/fitness', icon: '💪', description: 'Manage class bookings and member inquiries' },
  { name: 'Retail', path: '/retail', icon: '🛍️', description: 'Handle customer service and order inquiries' }
];

  useEffect(() => {
    const words = ['Car Dealers', ...useCases.map(useCase => useCase.name)];
    let index = 0;
    const interval = setInterval(() => {
      setTitleWord(words[index]);
      index = (index + 1) % words.length;
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleUseCaseClick = (useCase) => {
    // Navigate to the use case page
    window.location.href = useCase.path;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-white flex flex-col">
      {showCookiePopup && (
        <CookiePopup onAccept={handleAcceptCookies} onDecline={handleDeclineCookies} />
      )}
      <div className="flex-grow">
      <header className="flex justify-between items-center p-4 max-w-7xl mx-auto">
        <div className="flex items-center space-x-8">
          <div className="text-3xl font-bold text-gray-800 flex items-center">
            <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-10 h-10" />
            <h2 className="text-4xl font-bold">Aivira</h2>
          </div>
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              {navItems.map((item) => (
                <li key={item.name}>
                  {item.ref ? (
                    <button onClick={() => scrollTo(item.ref)} className="text-gray-600 hover:text-gray-900">
                      {item.name}
                    </button>
                  ) : (
                    <a 
                      href={item.href} 
                      target={item.target}
                      rel={item.target === '_blank' ? 'noopener noreferrer' : undefined}
                      className="text-gray-600 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          <Button variant="ghost" className="hidden md:inline-flex" onClick={() => {
              if (typeof window !== 'undefined') {
                  const accessToken = localStorage.getItem('accessToken');
                  if (accessToken) {
                    window.location.href = '/dashboard';
                  } else {
                    window.open('https://api.aivira.co/login', '_self');
                  }
              }
            }}>Login
          </Button>
          <Button variant="default" className="hidden md:inline-flex bg-gray-900 hover:bg-gray-800 text-white" onClick={() => {
  if (typeof window !== 'undefined') {
    window.open('https://api.aivira.co/login', '_self');
  }
}}>Try For Free</Button>
          <Button variant="ghost" className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu />
          </Button>
        </div>
      </header>

      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg absolute right-0 mt-2 py-2 w-48 z-10">
          {navItems.map((item) => (
            item.ref ? (
              <button
                key={item.name}
                onClick={() => scrollTo(item.ref)}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
              >
                {item.name}
              </button>
            ) : (
              <a
                key={item.name}
                href={item.href}
                target={item.target}
                rel={item.target === '_blank' ? 'noopener noreferrer' : undefined}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
              >
                {item.name}
              </a>
            )
          ))}
          <button
            onClick={() => {
              if (typeof window !== 'undefined') {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                  window.location.href = '/dashboard';
                } else {
                  window.open('https://api.aivira.co/login', '_self');
                }
              }
            }}
            className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
          >
            Login
          </button>
        </div>
      )}
      
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-20">
          <h1 className="text-5xl md:text-1xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
            The AI Receptionist for <span className="block md:inline">{titleWord}</span>
          </h1>
          <p className="text-xl md:text-2xl mb-10 text-gray-700">Revolutionize your customer service. Boost efficiency. Drive growth.</p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6 mb-16">
            <Button size="lg" className="bg-purple-600 hover:bg-purple-700 text-white px-8 py-4 text-lg rounded-full shadow-lg transform transition duration-300 hover:scale-105" onClick={() => {
  if (typeof window !== 'undefined') {
    window.open('https://api.aivira.co/login', '_self');
  }
}}>Start Free Trial</Button>
            <Button variant="outline" size="lg" className="border-purple-600 text-purple-600 hover:bg-purple-100 px-8 py-4 text-lg rounded-full shadow-lg transform transition duration-300 hover:scale-105" onClick={scrollToVideoAndPlay}>Watch Demo</Button>

          </div>

          <div className="p-10">
            <BMWContactOptions/>
          </div>
          
          <div className="aspect-w-16 aspect-h-9 max-w-5xl mx-auto mb-20">
            <iframe
              ref={videoRef}
              src={videoUrl.replace('autoplay=1', 'autoplay=0')}
              title="Aivira Demo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-[540px] rounded-2xl shadow-2xl"
            ></iframe>
          </div>

          <h2 className="text-4xl font-bold mb-10 bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">Tailored AI Solutions for Every Industry</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {useCases.map((useCase, index) => (
              <div 
                key={index}
                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer transform hover:-translate-y-2"
                onClick={() => handleUseCaseClick(useCase)}
              >
                <div className="text-5xl mb-6">{useCase.icon}</div>
                <h3 className="text-2xl font-semibold mb-4">{useCase.name}</h3>
                <p className="text-gray-600 mb-6">{useCase.description}</p>
                <span className="text-purple-600 font-medium flex items-center justify-center">
                  Explore 
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </section>

        <section ref={howItWorksRef} className="mb-32">
          <h2 className="text-4xl font-bold mb-6 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">How Aivira Works</h2>
          <p className="text-xl mb-16 text-center text-gray-700">Increase your efficiency and reduce your costs in 3 simple steps</p>
          
          <div className="grid md:grid-cols-3 gap-12">
            {[
              { title: "Integrate with your software", description: "1-click integration to your business management software", icon: "🔗" },
              { title: "Forward calls to Aivira", description: "Forward calls from any phone system to your Aivira number", icon: "📞" },
              { title: "Customize your calls", description: "Easily teach your AI to handle calls just the way you like it", icon: "🎛️" }
            ].map((step, index) => (
              <div key={index} className="bg-white p-8 rounded-2xl shadow-lg text-center">
                <div className="text-5xl mb-6">{step.icon}</div>
                <h3 className="text-2xl font-semibold mb-4">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>

          <div className="mt-20">
            <h2 className="text-3xl font-bold mb-10 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">Aivira: The Future of Business Communication</h2>
            <div className="flex flex-col items-center space-y-6 mt-8">
              {[
                "Available 24/7",
                "5x cheaper than a traditional call-center",
                "Handles all inquiries, bookings, and customer service"
              ].map((benefit, index) => (
                <div key={index} className="bg-white rounded-full px-8 py-4 shadow-lg flex items-center">
                  <Check className="text-green-500 mr-4" size={24} />
                  <span className="text-lg font-medium">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section ref={featuresRef} className="mb-32">
          <h2 className="text-4xl font-bold mb-16 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">Powerful Capabilities</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
            {[
              { title: "Human-like Conversation", description: "Engage customers with natural, lifelike interactions", icon: "💬" },
              { title: "5-Star Customer Experience", description: "Deliver top-tier customer service from the start", icon: "⭐" },
              { title: "SMS Integration", description: "Keep the conversation flowing even after the call ends", icon: "📱" },
              { title: "Appointment Management", description: "Streamline scheduling and reminders with ease", icon: "📅" },
              { title: "Multilingual Support", description: "Communicate with customers in their preferred language", icon: "🌍" },
              { title: "Custom AI Training", description: "Tailor the AI to your specific business needs", icon: "🧠" }
            ].map((feature, index) => (
              <div key={index} className="bg-white p-8 rounded-2xl shadow-lg">
                <div className="text-4xl mb-6">{feature.icon}</div>
                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section ref={pricingRef} className="mb-16">
          <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">Simple, Transparent Pricing</h2>
          <div className="max-w-7xl mx-auto">
            <div className="grid md:grid-cols-3 gap-8">
              {/* Starter Plan */}
              <div className="bg-white p-8 rounded-2xl shadow-xl relative">
                <div className="text-center mb-6">
                  <h3 className="text-2xl font-bold mb-2">Starter</h3>
                  <div className="text-4xl font-bold text-purple-600 mb-2">$99<span className="text-xl font-normal text-gray-600">/month</span></div>
                  <p className="text-gray-600">Perfect for small businesses</p>
                </div>
                <div className="space-y-4 mb-8">
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Up to 500 minutes/month</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>24/7 Call Handling</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Basic Call Analytics</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Email Notifications</span>
                  </div>
                </div>
                <Button 
                  className="w-full bg-purple-600 hover:bg-purple-700 text-white py-3 rounded-full"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open('https://api.aivira.co/login', '_self');
                    }
                  }}
                >
                  Start Free Trial
                </Button>
              </div>

              {/* Business Plan */}
              <div className="bg-white p-8 rounded-2xl shadow-xl relative transform scale-105 border-2 border-purple-500">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <span className="bg-purple-500 text-white px-4 py-1 rounded-full text-sm">Most Popular</span>
                </div>
                <div className="text-center mb-6">
                  <h3 className="text-2xl font-bold mb-2">Business</h3>
                  <div className="text-4xl font-bold text-purple-600 mb-2">$199<span className="text-xl font-normal text-gray-600">/month</span></div>
                  <p className="text-gray-600">For growing businesses</p>
                </div>
                <div className="space-y-4 mb-8">
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Up to 1,500 minutes/month</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Everything in Starter</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Advanced Analytics</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>SMS Notifications</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>CRM Integration</span>
                  </div>
                </div>
                <Button 
                  className="w-full bg-purple-600 hover:bg-purple-700 text-white py-3 rounded-full"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open('https://api.aivira.co/login', '_self');
                    }
                  }}
                >
                  Start Free Trial
                </Button>
              </div>

              {/* Pro Plan */}
              <div className="bg-white p-8 rounded-2xl shadow-xl relative">
                <div className="text-center mb-6">
                  <h3 className="text-2xl font-bold mb-2">Pro</h3>
                  <div className="text-4xl font-bold text-purple-600 mb-2">$399<span className="text-xl font-normal text-gray-600">/month</span></div>
                  <p className="text-gray-600">For power users</p>
                </div>
                <div className="space-y-4 mb-8">
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Up to 4,000 minutes/month</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Everything in Business</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Priority Support</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Custom Integrations</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Check className="text-green-500 flex-shrink-0" size={20} />
                    <span>Dedicated Account Manager</span>
                  </div>
                </div>
                <Button 
                  className="w-full bg-purple-600 hover:bg-purple-700 text-white py-3 rounded-full"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open('https://api.aivira.co/login', '_self');
                    }
                  }}
                >
                  Start Free Trial
                </Button>
              </div>
            </div>

            <div className="mt-12 text-center">
              <p className="text-2xl mb-8">Need a tailored solution for your business?{' '}
                <button 
                  onClick={() => {
                    if (typeof window !== 'undefined' && window.$crisp) {
                      window.$crisp.push(['do', 'chat:open']);
                    }
                  }}
                  className="text-purple-600 hover:text-purple-700 font-semibold underline cursor-pointer"
                >
                  Contact us
                </button>
              </p>
              <p className="text-lg mb-4">All plans include:</p>
              <div className="grid md:grid-cols-3 gap-6 max-w-4xl mx-auto">
                <div className="text-center">
                  <div className="text-4xl mb-4">🎯</div>
                  <h4 className="text-xl font-semibold mb-2">No Setup Fee</h4>
                  <p className="text-gray-600">Get started immediately</p>
                </div>
                <div className="text-center">
                  <div className="text-4xl mb-4">📞</div>
                  <h4 className="text-xl font-semibold mb-2">14-Day Free Trial</h4>
                  <p className="text-gray-600">Try before you commit</p>
                </div>
                <div className="text-center">
                  <div className="text-4xl mb-4">🚀</div>
                  <h4 className="text-xl font-semibold mb-2">Cancel Anytime</h4>
                  <p className="text-gray-600">No long-term contracts</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={faqRef} className="mb-32">
          <h2 className="text-4xl font-bold mb-16 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">Frequently Asked Questions</h2>
          <div className="max-w-4xl mx-auto">
            {faqItems.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
        </section>

        <section className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-10 text-gray-700">Join the AI revolution and take your customer service to the next level.</p>
          <Button size="lg" className="bg-purple-600 hover:bg-purple-700 text-white px-10 py-4 text-xl rounded-full shadow-lg transform transition duration-300 hover:scale-105" onClick={() => {
            if (typeof window !== 'undefined') {
              window.open('https://api.aivira.co/login', '_self');
            }
          }}>Get Started Now</Button>
        </section>
      </main>
      </div>
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-4">Aivira</h3>
              <p>The AI Receptionist for Small Businesses</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-4">Quick Links</h3>
              <ul>
                {navItems.map((item, index) => (
                  <li key={index} className="mb-2">
                    {item.ref ? (
                      <button onClick={() => scrollTo(item.ref)} className="text-gray-300 hover:text-white">
                        {item.name}
                      </button>
                    ) : (
                      <a 
                        href={item.href} 
                        target={item.target}
                        rel={item.target === '_blank' ? 'noopener noreferrer' : undefined}
                        className="text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h3 className="text-xl font-bold mb-4">Legal</h3>
              <ul>
                <li className="mb-2">
                  <Link to="/privacy" className="text-gray-300 hover:text-white">Privacy Policy</Link>
                </li>
                <li className="mb-2">
                  <Link to="/terms" className="text-gray-300 hover:text-white">Terms and Conditions</Link>
                </li>
                <li className="mb-2">
                  <Link to="/cookie-policy" className="text-gray-300 hover:text-white">Cookie Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p>&copy; {new Date().getFullYear()} Aivira. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
