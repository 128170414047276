import React, { useContext } from 'react';
import { translations } from '../i18n/translations';

// Create a context to store the current language
export const LanguageContext = React.createContext({
  language: 'en',
  setLanguage: () => {},
});

export const useTranslation = () => {
  const { language } = useContext(LanguageContext);

  const t = (key) => {
    const keys = key.split('.');
    let value = translations[language];
    
    for (const k of keys) {
      if (value && value[k]) {
        value = value[k];
      } else {
        console.warn(`Translation key not found: ${key}`);
        return key;
      }
    }

    if (typeof value === 'string') {
      return value;
    }

    console.warn(`Invalid translation key: ${key}`);
    return key;
  };

  return { t, language };
};
