import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p>This is a placeholder for the Privacy Policy. The actual content will be added here.</p>
    </div>
  );
};

export default PrivacyPolicy;