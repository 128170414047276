export const translations = {
  en: {
    common: {
      login: 'Login',
      tryForFree: 'Try For Free',
      startFreeTrial: 'Start Free Trial',
      watchDemo: 'Watch Demo',
      getStartedNow: 'Get Started Now',
    },
    nav: {
      howItWorks: 'How it works',
      features: 'Features',
      pricing: 'Pricing',
      faq: 'FAQ',
      blog: 'Blog',
    },
    hero: {
      title: 'The AI Receptionist for',
      subtitle: 'Revolutionize your customer service. Boost efficiency. Drive growth.',
    },
    cookies: {
      title: 'We use cookies',
      description: 'We use cookies to ensure you get the best experience on our website. For more information on how we use cookies, please see our cookie policy.',
      consent: 'By clicking "Accept", you agree to our use of cookies.',
      accept: 'Accept',
      decline: 'Decline',
    },
    bmwContact: {
      title: 'Experience Our Car Dealership AI Receptionist!',
      subtitle: 'Discover the future of car dealership customer service. Try our interactive voice on our demo line:',
      services: {
        testDrive: {
          title: 'Schedule a Test Drive',
          description: 'Experience the thrill of driving a BMW'
        },
        models: {
          title: 'Inquire About Models',
          description: 'Get details on our latest BMW lineup'
        },
        service: {
          title: 'Service Options',
          description: 'Learn about our premium service packages'
        }
      }
    },
    howItWorks: {
      title: 'How Aivira Works',
      subtitle: 'Increase your efficiency and reduce your costs in 3 simple steps',
      steps: {
        integrate: {
          title: 'Integrate with your software',
          description: '1-click integration to your business management software'
        },
        forward: {
          title: 'Forward calls to Aivira',
          description: 'Forward calls from any phone system to your Aivira number'
        },
        customize: {
          title: 'Customize your calls',
          description: 'Easily teach your AI to handle calls just the way you like it'
        }
      }
    },
    features: {
      title: 'Powerful Capabilities',
      items: {
        conversation: {
          title: 'Human-like Conversation',
          description: 'Engage customers with natural, lifelike interactions'
        },
        experience: {
          title: '5-Star Customer Experience',
          description: 'Deliver top-tier customer service from the start'
        },
        sms: {
          title: 'SMS Integration',
          description: 'Keep the conversation flowing even after the call ends'
        },
        appointments: {
          title: 'Appointment Management',
          description: 'Streamline scheduling and reminders with ease'
        },
        multilingual: {
          title: 'Multilingual Support',
          description: 'Communicate with customers in their preferred language'
        },
        training: {
          title: 'Custom AI Training',
          description: 'Tailor the AI to your specific business needs'
        }
      }
    },
    pricing: {
      title: 'Flexible Pricing',
      freeTitle: 'Start for Free',
      freeFeatures: [
        'No upfront costs — 60 minutes of free access to explore everything',
        'Ongoing support through our Discord community',
        'No credit card required to start'
      ],
      payAsYouGrow: {
        title: 'Pay As You Grow',
        voice: {
          title: 'Voice API for Calls',
          elevenLabs: 'ElevenLabs voices: $0.12/min',
          openAI: 'OpenAI / Deepgram: $0.13/min'
        },
        ai: {
          title: 'AI Agents (LLM)',
          gptMini: 'GPT-4o mini: $0.011/min',
          gptFull: 'Full GPT-4o model: $0.10/min',
          claudeHaiku: 'Claude 3 (Haiku): $0.016/min',
          claudeSonnet: 'Claude 3.5 (Sonnet): $0.11/min',
          ownLLM: 'Use your own LLM for free'
        },
        telephony: {
          title: 'Telephony',
          aivira: 'Using Aivira\'s Twilio: $0.06/min',
          own: 'Bring your own Twilio: No charge'
        }
      }
    },
    faq: {
      title: 'Frequently Asked Questions'
    },
    footer: {
      title: 'Aivira',
      subtitle: 'The AI Receptionist for Small Businesses',
      legal: {
        title: 'Legal',
        privacy: 'Privacy Policy',
        terms: 'Terms and Conditions',
        cookies: 'Cookie Policy'
      },
      copyright: '© {year} Aivira. All rights reserved.'
    }
  },
  // Add other languages here
  es: {
    common: {
      login: 'Iniciar sesión',
      tryForFree: 'Prueba Gratis',
      startFreeTrial: 'Comenzar Prueba Gratuita',
      watchDemo: 'Ver Demo',
      getStartedNow: 'Empezar Ahora',
    },
    nav: {
      howItWorks: 'Cómo funciona',
      features: 'Características',
      pricing: 'Precios',
      faq: 'Preguntas frecuentes',
      blog: 'Blog',
    },
    hero: {
      title: 'El Recepcionista AI para',
      subtitle: 'Revoluciona tu servicio al cliente. Aumenta la eficiencia. Impulsa el crecimiento.',
    },
    cookies: {
      title: 'Utilizamos cookies',
      description: 'Utilizamos cookies para asegurarnos de que obtenga la mejor experiencia en nuestro sitio web. Para obtener más información sobre cómo usamos las cookies, consulte nuestra política de cookies.',
      consent: 'Al hacer clic en "Aceptar", acepta nuestro uso de cookies.',
      accept: 'Aceptar',
      decline: 'Rechazar',
    },
    bmwContact: {
      title: '¡Experimente Nuestro Recepcionista AI para Concesionarios!',
      subtitle: 'Descubra el futuro del servicio al cliente en concesionarios. Pruebe nuestra voz interactiva en nuestra línea de demostración:',
      services: {
        testDrive: {
          title: 'Programar una Prueba de Manejo',
          description: 'Experimente la emoción de conducir un BMW'
        },
        models: {
          title: 'Consultar sobre Modelos',
          description: 'Obtenga detalles sobre nuestra última línea de BMW'
        },
        service: {
          title: 'Opciones de Servicio',
          description: 'Conozca nuestros paquetes de servicio premium'
        }
      }
    },
    howItWorks: {
      title: 'Cómo Funciona Aivira',
      subtitle: 'Aumente su eficiencia y reduzca sus costos en 3 simples pasos',
      steps: {
        integrate: {
          title: 'Integre con su software',
          description: 'Integración con un clic a su software de gestión empresarial'
        },
        forward: {
          title: 'Desvíe llamadas a Aivira',
          description: 'Desvíe llamadas desde cualquier sistema telefónico a su número Aivira'
        },
        customize: {
          title: 'Personalice sus llamadas',
          description: 'Enseñe fácilmente a su IA a manejar las llamadas como usted prefiera'
        }
      }
    },
    features: {
      title: 'Capacidades Potentes',
      items: {
        conversation: {
          title: 'Conversación Natural',
          description: 'Interactúe con los clientes de forma natural y realista'
        },
        experience: {
          title: 'Experiencia 5 Estrellas',
          description: 'Brinde servicio al cliente de primera clase desde el inicio'
        },
        sms: {
          title: 'Integración SMS',
          description: 'Mantenga la conversación fluyendo incluso después de la llamada'
        },
        appointments: {
          title: 'Gestión de Citas',
          description: 'Optimice la programación y recordatorios fácilmente'
        },
        multilingual: {
          title: 'Soporte Multilingüe',
          description: 'Comuníquese con los clientes en su idioma preferido'
        },
        training: {
          title: 'Entrenamiento IA Personalizado',
          description: 'Adapte la IA a las necesidades específicas de su negocio'
        }
      }
    },
    pricing: {
      title: 'Precios Flexibles',
      freeTitle: 'Comience Gratis',
      freeFeatures: [
        'Sin costos iniciales — 60 minutos de acceso gratuito para explorar todo',
        'Soporte continuo a través de nuestra comunidad Discord',
        'No se requiere tarjeta de crédito para comenzar'
      ],
      payAsYouGrow: {
        title: 'Pague Mientras Crece',
        voice: {
          title: 'API de Voz para Llamadas',
          elevenLabs: 'Voces ElevenLabs: $0.12/min',
          openAI: 'OpenAI / Deepgram: $0.13/min'
        },
        ai: {
          title: 'Agentes IA (LLM)',
          gptMini: 'GPT-4o mini: $0.011/min',
          gptFull: 'Modelo GPT-4o completo: $0.10/min',
          claudeHaiku: 'Claude 3 (Haiku): $0.016/min',
          claudeSonnet: 'Claude 3.5 (Sonnet): $0.11/min',
          ownLLM: 'Use su propio LLM gratis'
        },
        telephony: {
          title: 'Telefonía',
          aivira: 'Usando Twilio de Aivira: $0.06/min',
          own: 'Traiga su propio Twilio: Sin cargo'
        }
      }
    },
    faq: {
      title: 'Preguntas Frecuentes'
    },
    footer: {
      title: 'Aivira',
      subtitle: 'El Recepcionista IA para Pequeñas Empresas',
      legal: {
        title: 'Legal',
        privacy: 'Política de Privacidad',
        terms: 'Términos y Condiciones',
        cookies: 'Política de Cookies'
      },
      copyright: '© {year} Aivira. Todos los derechos reservados.'
    }
  },
  fr: {
    common: {
      login: 'Connexion',
      tryForFree: 'Essayer Gratuitement',
      startFreeTrial: 'Commencer l\'essai gratuit',
      watchDemo: 'Voir la Démo',
      getStartedNow: 'Commencer Maintenant',
    },
    nav: {
      howItWorks: 'Comment ça marche',
      features: 'Fonctionnalités',
      pricing: 'Tarifs',
      faq: 'FAQ',
      blog: 'Blog',
    },
    hero: {
      title: 'La Réceptionniste IA pour',
      subtitle: 'Révolutionnez votre service client. Augmentez l\'efficacité. Stimulez la croissance.',
    },
    cookies: {
      title: 'Nous utilisons des cookies',
      description: 'Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Pour plus d\'informations sur notre utilisation des cookies, consultez notre politique en matière de cookies.',
      consent: 'En cliquant sur "Accepter", vous acceptez notre utilisation des cookies.',
      accept: 'Accepter',
      decline: 'Refuser',
    },
    bmwContact: {
      title: 'Découvrez Notre Réceptionniste IA pour Concessions Automobiles !',
      subtitle: 'Découvrez l\'avenir du service client automobile. Essayez notre voix interactive sur notre ligne de démonstration :',
      services: {
        testDrive: {
          title: 'Planifier un Essai',
          description: 'Vivez l\'expérience de conduire une BMW'
        },
        models: {
          title: 'Se Renseigner sur les Modèles',
          description: 'Découvrez notre dernière gamme BMW'
        },
        service: {
          title: 'Options de Service',
          description: 'Découvrez nos forfaits service premium'
        }
      }
    },
    howItWorks: {
      title: 'Comment Fonctionne Aivira',
      subtitle: 'Augmentez votre efficacité et réduisez vos coûts en 3 étapes simples',
      steps: {
        integrate: {
          title: 'Intégrez votre logiciel',
          description: 'Intégration en 1 clic à votre logiciel de gestion'
        },
        forward: {
          title: 'Transférez les appels à Aivira',
          description: 'Transférez les appels de n\'importe quel système téléphonique vers votre numéro Aivira'
        },
        customize: {
          title: 'Personnalisez vos appels',
          description: 'Apprenez facilement à votre IA à gérer les appels comme vous le souhaitez'
        }
      }
    },
    features: {
      title: 'Fonctionnalités Puissantes',
      items: {
        conversation: {
          title: 'Conversation Naturelle',
          description: 'Engagez vos clients avec des interactions naturelles et réalistes'
        },
        experience: {
          title: 'Expérience Client 5 Étoiles',
          description: 'Offrez un service client haut de gamme dès le début'
        },
        sms: {
          title: 'Intégration SMS',
          description: 'Maintenez la conversation même après l\'appel'
        },
        appointments: {
          title: 'Gestion des Rendez-vous',
          description: 'Simplifiez la planification et les rappels facilement'
        },
        multilingual: {
          title: 'Support Multilingue',
          description: 'Communiquez avec les clients dans leur langue préférée'
        },
        training: {
          title: 'Formation IA Personnalisée',
          description: 'Adaptez l\'IA aux besoins spécifiques de votre entreprise'
        }
      }
    },
    pricing: {
      title: 'Tarification Flexible',
      freeTitle: 'Commencez Gratuitement',
      freeFeatures: [
        'Aucun coût initial — 60 minutes d\'accès gratuit pour tout explorer',
        'Support continu via notre communauté Discord',
        'Pas de carte de crédit requise pour commencer'
      ],
      payAsYouGrow: {
        title: 'Payez selon votre croissance',
        voice: {
          title: 'API Vocale pour les Appels',
          elevenLabs: 'Voix ElevenLabs : 0,12$/min',
          openAI: 'OpenAI / Deepgram : 0,13$/min'
        },
        ai: {
          title: 'Agents IA (LLM)',
          gptMini: 'GPT-4o mini : 0,011$/min',
          gptFull: 'Modèle GPT-4o complet : 0,10$/min',
          claudeHaiku: 'Claude 3 (Haiku) : 0,016$/min',
          claudeSonnet: 'Claude 3.5 (Sonnet) : 0,11$/min',
          ownLLM: 'Utilisez votre propre LLM gratuitement'
        },
        telephony: {
          title: 'Téléphonie',
          aivira: 'Utilisation de Twilio d\'Aivira : 0,06$/min',
          own: 'Apportez votre propre Twilio : Gratuit'
        }
      }
    },
    faq: {
      title: 'Questions Fréquentes'
    },
    footer: {
      title: 'Aivira',
      subtitle: 'La Réceptionniste IA pour les Petites Entreprises',
      legal: {
        title: 'Mentions Légales',
        privacy: 'Politique de Confidentialité',
        terms: 'Conditions Générales',
        cookies: 'Politique des Cookies'
      },
      copyright: '© {year} Aivira. Tous droits réservés.'
    }
  },
  nl: {
    common: {
      login: 'Inloggen',
      tryForFree: 'Gratis Proberen',
      startFreeTrial: 'Start Gratis Proefperiode',
      watchDemo: 'Bekijk Demo',
      getStartedNow: 'Nu Beginnen',
    },
    nav: {
      howItWorks: 'Hoe het werkt',
      features: 'Functies',
      pricing: 'Prijzen',
      faq: 'FAQ',
      blog: 'Blog',
    },
    hero: {
      title: 'De AI-Receptioniste voor',
      subtitle: 'Revolutioneer uw klantenservice. Verhoog efficiëntie. Stimuleer groei.',
    },
    cookies: {
      title: 'Wij gebruiken cookies',
      description: 'Wij gebruiken cookies om u de beste ervaring op onze website te garanderen. Voor meer informatie over ons gebruik van cookies, raadpleeg ons cookiebeleid.',
      consent: 'Door op "Accepteren" te klikken, gaat u akkoord met ons gebruik van cookies.',
      accept: 'Accepteren',
      decline: 'Weigeren',
    },
    bmwContact: {
      title: 'Ervaar Onze AI-Receptioniste voor Autodealers!',
      subtitle: 'Ontdek de toekomst van klantenservice bij autodealers. Probeer onze interactieve stem op onze demolijn:',
      services: {
        testDrive: {
          title: 'Plan een Proefrit',
          description: 'Ervaar de sensatie van het rijden in een BMW'
        },
        models: {
          title: 'Informeer naar Modellen',
          description: 'Ontdek details over onze nieuwste BMW-modellen'
        },
        service: {
          title: 'Service-opties',
          description: 'Leer meer over onze premium servicepakketten'
        }
      }
    },
    howItWorks: {
      title: 'Hoe Aivira Werkt',
      subtitle: 'Verhoog uw efficiëntie en verlaag uw kosten in 3 eenvoudige stappen',
      steps: {
        integrate: {
          title: 'Integreer met uw software',
          description: '1-klik integratie met uw bedrijfsbeheersoftware'
        },
        forward: {
          title: 'Stuur gesprekken door naar Aivira',
          description: 'Stuur gesprekken door van elk telefoonsysteem naar uw Aivira-nummer'
        },
        customize: {
          title: 'Personaliseer uw gesprekken',
          description: 'Leer uw AI eenvoudig om gesprekken te behandelen zoals u dat wilt'
        }
      }
    },
    features: {
      title: 'Krachtige Mogelijkheden',
      items: {
        conversation: {
          title: 'Menselijke Conversatie',
          description: 'Betrek klanten met natuurlijke, levensechte interacties'
        },
        experience: {
          title: '5-Sterren Klantervaring',
          description: 'Lever vanaf het begin eersteklas klantenservice'
        },
        sms: {
          title: 'SMS-integratie',
          description: 'Houd het gesprek gaande, zelfs na het telefoongesprek'
        },
        appointments: {
          title: 'Afsprakenbeheer',
          description: 'Stroomlijn planning en herinneringen met gemak'
        },
        multilingual: {
          title: 'Meertalige Ondersteuning',
          description: 'Communiceer met klanten in hun voorkeurstaal'
        },
        training: {
          title: 'Aangepaste AI-training',
          description: 'Pas de AI aan aan uw specifieke bedrijfsbehoeften'
        }
      }
    },
    pricing: {
      title: 'Flexibele Prijzen',
      freeTitle: 'Begin Gratis',
      freeFeatures: [
        'Geen opstartkosten — 60 minuten gratis toegang om alles te verkennen',
        'Doorlopende ondersteuning via onze Discord-community',
        'Geen creditcard nodig om te beginnen'
      ],
      payAsYouGrow: {
        title: 'Betaal naar gebruik',
        voice: {
          title: 'Voice API voor Gesprekken',
          elevenLabs: 'ElevenLabs stemmen: €0,12/min',
          openAI: 'OpenAI / Deepgram: €0,13/min'
        },
        ai: {
          title: 'AI-Agents (LLM)',
          gptMini: 'GPT-4o mini: €0,011/min',
          gptFull: 'Volledig GPT-4o model: €0,10/min',
          claudeHaiku: 'Claude 3 (Haiku): €0,016/min',
          claudeSonnet: 'Claude 3.5 (Sonnet): €0,11/min',
          ownLLM: 'Gebruik uw eigen LLM gratis'
        },
        telephony: {
          title: 'Telefonie',
          aivira: 'Met Aivira\'s Twilio: €0,06/min',
          own: 'Breng uw eigen Twilio: Geen kosten'
        }
      }
    },
    faq: {
      title: 'Veelgestelde Vragen'
    },
    footer: {
      title: 'Aivira',
      subtitle: 'De AI-Receptioniste voor Kleine Bedrijven',
      legal: {
        title: 'Juridisch',
        privacy: 'Privacybeleid',
        terms: 'Algemene Voorwaarden',
        cookies: 'Cookiebeleid'
      },
      copyright: '© {year} Aivira. Alle rechten voorbehouden.'
    }
  }
}